@import './src/assets/_color.scss';

.project-teams {
  .col-xxl-6 {
    .project-teams-hover-img {
      height: 270px;
      &:hover img {
        opacity: 0.8;
      }
      img{
        object-fit: cover;
      }
    }
  }
  .color-text-right {
    background-image: $background-image;
    padding: 5em;
    h2 {
      font-size: 67px;
    }
    .project-teams-span-padding {
      padding: 4em 0;
      span {
        font-size: 25px;
      }
    }
    // icon
    .d-flex {
      .line-width-icon-2 {
        position: relative;
        width: 20%;
        top: -13px;
        left: 10px;

        &::before {
          content: '';
          position: absolute;
          width: 15%;
          padding: 0 0 2px 0;
          background: $white;
          margin-top: 2px;
        }
        i {
          border: solid $white;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          position: absolute;
        }
        .right-icon-2 {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-top: -1.5px;
          left: 17px;
        }
      }
      &:hover .line-width-icon-2  {
        &::before {
          width: 28%;
        }
        i {
          margin-left: 21px;
        }
      }
    }
  }
}
@media (max-width:1025px){

  .project-teams{
    flex-direction: column;
  }
    .project-teams .color-text-right .d-flex .line-width-icon-2 {
    width: 26%;
    top: -12px;
    left: 10px;
  }
  .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    left: 27px;
    top: 0px;
  }
  }
  @media (max-width:769px) {
        .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
      left: 25px;
  }
        .project-teams .color-text-right .d-flex .line-width-icon-2 {
      width: 34%;
  }
      .project-teams .color-text-right .d-flex:hover .line-width-icon-2::before {
    width: 22%;
  }
  }
  @media (max-width:541px) {
      .project-teams .color-text-right h2 {
    font-size: 39px;
  }
      .project-teams .color-text-right .project-teams-span-padding {
    padding: 1em 0;
  }
      .project-teams .color-text-right .project-teams-span-padding span {
    font-size: 16px;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2 {
    width: 56%;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    top: 4px;
  }
  }
  @media (max-width:415px) {
      .project-teams .color-text-right {
    padding: 2em;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    left: 23px;
  }
  }
  @media (max-width:376px) {

      .project-teams .color-text-right h2 {
    font-size: 28px;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2::before {
    margin-top: 4%;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    left: 20px;
  }
  
  
  }
  @media (max-width:361px) {
      .project-teams .color-text-right .d-flex .line-width-icon-2 {
    width: 69%;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    top: 3px;
    left: 25px;
  }
      .project-teams .color-text-right .d-flex .line-width-icon-2::before {
    margin-top: 3%;
  }
  
  }
  @media (max-width:321px) {
      .project-teams .color-text-right .d-flex .line-width-icon-2 .right-icon-2 {
    left: 21px;
    top: 2px;
  }
  }

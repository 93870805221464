.p-footer {
  display: flex !important;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-size: 45px;
  }

  a {
    color: black;
    text-decoration: none;
  }

  &_contentIcon {
    display: inline-block;
    margin: 5px;
  }
  &_icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 6px;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    $icons: 'fb', 'google', 'ig', 'in', 'zalo', 'youtube';
    @each $icon in $icons {
      &-#{$icon} {
        background-image: url('../../../icon/icon_#{$icon}.svg');
      }
    }
  }

  &_copyright {
    margin-top: 50px;
    text-decoration: none;
    color: black;
  }

  &_contact {
    font-weight: 500 normal;
    text-decoration: none;
    color: black;
  }

  &_pic {
    width: 280px;
    display: flex;
    margin-top: 5px;
  }

  &_button {
    position: relative;
    display: flex;
    justify-content: center;
    color: black;
    width: 100%;
    background: transparent;
    margin-top: 10px;
    &:before {
      content: '';
      width: 27px;
      height: 2px;
      background: slategray;
      position: absolute;
      top: 50%;
      margin-top: -1px;
      right: 24px;
      transition: all 0.3s ease;
      margin-right: 40px;
    }

    &:hover:before {
      right: 34px;
      width: 35px;
    }

    &_contact {
      position: relative;
      // margin-right: 70px;
      color: black;
      font-weight: bold;
    }

    &_explore {
      position: absolute;
      margin-right: 90px;
      visibility: hidden;
      color: black;
      font-weight: bold;
    }

    &:after {
      content: '';
      width: 0;
      /*   height: 0; */
      border-style: solid;
      border-width: 5px 0 5px 5px;
      border-color: transparent transparent transparent #000000;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      margin-right: 40px;
      right: 20px;
      transition: all 0.3s ease;
    }

    &:hover:after {
      right: 34px;
      right: 30px;
    }

    > .explore {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }

    &:hover > &_explore {
      visibility: visible;
      opacity: 1;
    }

    > &_contact {
      transition: visibility 0s, opacity 0.5s linear;
    }

    :hover > &_contact {
      visibility: hidden;
      opacity: 1;
    }
  }
}

@import './src/assets/_color.scss';

.red {
  color: $red;
}
.project-home {
  .project-home-text {
    padding: 6em 0 6em 0;
    .project-auto {
      margin: auto;
      span {
        // background-image:url("/media/images/home-page/Path.svg ");
        font-size: 45px;
        color: #005db4;
      }
    }
    .youtube-player {
      min-height: 500px;
    }
  }

  .project-text {
    width: 100%;
    padding: 0 0 7em 0;
    h1 {
      font-size: 67px;
    }
    .justify-content-between {
      .col-xl-6 {
        .fz-20px {
          font-size: $fz-20;
        }
      }
      .col-xl-3 {
        .fz-16px {
          font-size: $fz-16;
          margin-left: 28px;
        }
        .project-button-radio {
          width: 178px;
          height: 178px;
          border-radius: 100%;
          background-color: $white-hex;
          border: 1px solid $gray-800;
          right: 0%;
          bottom: -31px;
          .line-width {
            position: relative;
            width: 86%;

            margin-left: 12px;
            &::before {
              content: '';
              position: absolute;
              width: 20%;
              padding: 0 0 2px 0;
              background: #383838;
              top: 7.9px;
              left: 48px;
            }
            &:hover {
              &::before {
                width: 28%;
              }
              i {
                margin-left: 24px;
              }
            }

            i {
              border: solid #383838;
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 3px;
            }
            .right {
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
  .products {
    .row {
      .width-600 {
        width: 600px;
        padding: 0 0 4em 0;
        .image {
          display: block;
          width: 100%;
          height: 668px;
          object-fit: cover;
        }
        .products-overlay-img {
          position: absolute;
          bottom: 0;
          background-image: $background-image;
          width: 100%;
          transition: 0.5s ease;
          opacity: 0;
          color: white;
          height: 100%;
          padding: 4em 3em;
          &:hover {
            opacity: 1;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          }
          .padding-hover {
            .button-hover {
              font-size: 33px;
              width: 284px;
              height: 3em;
              // padding: 11px 0;
              background-color: #40404000;
              border: 0px;
              .left-img {
                display: flex;
                height: 100%;
              }
            }
            .text-hover {
              padding: 8em 0 2em 0;
              text-align: left;
              p {
                font-size: 25px;
              }
              .line-width-hove {
                &::before {
                  content: '';
                  position: absolute;
                  width: 85%;
                  padding: 0 0 2px 0;
                  background: $white;
                  top: 27rem;
                }
                h3 {
                  color: $white;
                  font-size: 37px;
                }
              }
            }
            .padding-text-span {
              padding: 2em 0 6.5em 0;
              span {
                font-size: 16px;
              }
            }

            // icon
            .d-flex {
              .line-width-icon {
                position: relative;
                width: 20%;
                top: -12px;
                left: 10px;

                &::before {
                  content: '';
                  position: absolute;
                  width: 15%;
                  padding: 0 0 2px 0;
                  background: $white;
                  margin-top: 3%;
                }
                i {
                  border: solid $white;
                  border-width: 0 3px 3px 0;
                  display: inline-block;
                  padding: 3px;
                }
                .right-icon {
                  transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  position: absolute;
                  left: 8px;
                }
              }
              &:hover .line-width-icon {
                &::before {
                  width: 28%;
                }
                i {
                  margin-left: 13px;
                }
              }
            }
          }
        }
      }
    }
    // text
    .product-text {
      padding: 2em 0;
      p {
        font-size: 20px;
        color: #a5a5a5;
        //background: url('');
      }
      span {
        font-size: 37px;
        color: $black;
      }
    }
  }
}
// 1025
@media (max-width: 1025px) {
  .project-home .project-home-text .project-auto span {
    font-size: 32px;
  }

  .project-home .project-text h1 {
    font-size: 60px;
  }
  .project-home .project-text .justify-content-between .col-xl-6 .fz-20px {
    font-size: 16px;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    margin-top: 9px;
    margin-left: 24px;
    width: 60%;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio {
    width: 110px;
    height: 110px;
    bottom: -5px;
    right: 28px;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width::before {
    top: 8px;
    left: 10px;
    width: 40%;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width:hover::before {
    width: 50%;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width:hover
    i {
    margin-left: 10px;
  }
  .project-home .products .row .width-600 {
    width: 444px;
  }
  .project-home .products .row .width-600 .image {
    height: 510px;
  }
  .project-home .products .product-text span {
    font-size: 26px;
  }
  .project-home .products .row .width-600 .products-overlay-img .padding-hover .text-hover {
    padding: 2em 0 0em 0;
  }
  .project-home .products .row .width-600 .products-overlay-img {
    padding: 2em 2em;
  }
  .project-home .products .row .width-600 .products-overlay-img .padding-hover .padding-text-span {
    padding: 2em 0 2.5em 0;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .text-hover
    .line-width-hove::before {
    top: 18rem;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex
    .line-width-icon {
    width: 26%;
  }
}
@media (max-width: 769px) {
  .project-home .project-home-text .project-auto span {
    font-size: 27px;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width::before {
    top: 12px;
    left: inherit;
    right: -30px;
    width: 70%;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    top: 42px;
    width: 50%;
    left: 0;
    position: absolute;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio {
    bottom: 0;
    top: -48px;
    border: 0px;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width:hover
    i {
    margin-left: 0px;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width:hover::before {
    width: 68%;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .fz-16px {
    margin-left: 2px;
  }
  .project-home .project-text {
    padding: 0 0 2em 0;
  }
  .project-home
    .project-text
    .justify-content-between
    .col-xl-3
    .project-button-radio
    .line-width
    .right {
    margin-right: -1rem;
    margin-top: 8.5px;
  }
  // .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
  //   width: 50%;
  // }
  .project-home .products .row .width-600 {
    width: 345px;
    margin: auto;
  }
  .project-home .products .row .width-600 .image {
    // height: 375px;
  }
  .project-home .products .row .width-600 .products-overlay-img {
    padding: 3em 1em;
  }
  .project-home .products .row .width-600 .products-overlay-img .padding-hover .button-hover {
    font-size: 21px;
    width: 200px;
  }
  .project-home .products .row .width-600 .products-overlay-img .padding-hover .text-hover {
    padding: 1em 0 0em 0;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .text-hover
    .line-width-hove
    h3 {
    color: #ffffff;
    font-size: 28px;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .text-hover
    .line-width-hove::before {
    top: 16rem;
  }
  .project-home .products .row .width-600 .products-overlay-img .padding-hover .padding-text-span {
    padding: 1em 0 1.5em 0;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .padding-text-span
    span {
    font-size: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex
    .line-width-icon {
    width: 35%;
  }
}
@media (max-width: 541px) {
  .project-home .project-home-text {
    padding: 2em 0;
  }
  .project-home .project-home-text .project-auto span {
    font-size: 18px;
  }
  .project-home .project-text h1 {
    font-size: 38px;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    width: 29%;
    margin-left: -25%;
  }
  .project-home .products .row .width-600 {
    width: auto;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex
    .line-width-icon {
    width: 21%;
    top: -13px;
  }
}
@media (max-width: 415px) {
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    width: 38%;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex
    .line-width-icon {
    width: 28%;
  }
  .project-home .products .product-text {
    padding: 1em 0;
  }
}
@media (max-width: 376px) {
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    width: 42%;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex:hover
    .line-width-icon
    i {
    margin-left: 16px;
  }
}
@media (max-width: 361px) {
  .project-home .project-home-text .project-auto span {
    font-size: 14px;
    font-weight: 600;
  }
  .project-home
    .products
    .row
    .width-600
    .products-overlay-img
    .padding-hover
    .d-flex:hover
    .line-width-icon
    i {
    margin-left: 10px;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    width: 44%;
  }
}
@media (max-width: 321px) {
  .project-home .project-text h1 {
    font-size: 31px;
  }
  .project-home .project-text .justify-content-between .col-xl-6 .fz-20px {
    font-size: 14px;
  }
  .project-home .project-text .justify-content-between .col-xl-3 .project-button-radio .line-width {
    width: 50%;
  }
}
//

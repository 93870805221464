@import './src/assets/_color.scss';

.project-trusted {
  padding: 9em 0;
  .container {
    h2 {
      font-size: 67px;
    }
    .col-xxl-7 {
      p {
        font-size: $fz-25;
      }
    }
    .padding-project-trusted {
      padding: 2em 0 10em 0;
      width: 1360px;
      .col-xxl-3 {
        .project-trusted-button {
          margin-top: 50px;
          background-color: #404040;
          border: 0px;
          color: $white;
          height: 85px;
          width: 261px;
          font-size: $fz-25;
          display: block;
          // img{
          //   margin: auto;
          // }
        }
        .trusted-hover-buttom {
          a {
            display: none;
          }
        }
        .project-trusted-button {
          img {
            margin: auto;
            padding: 10px;
            height: 100%;
          }
        }
        &:hover  .project-trusted-button {
          margin-top: 22px;
        }
        &:hover .trusted-hover-buttom {
          padding: 1em 0;
          a {
            display: block !important;
            transition: 0.5s ease;
            color: $black;
            font-size: 16px;
          }
          .line-width-icon-3 {
            position: relative;
            width: 20%;
            top: 12px;
            left: 1rem;

            &::before {
              content: '';
              position: absolute;
              width: 40%;
              padding: 0 0 3px 0;
              background: $black;
            }

            i {
              border: solid $black;
              border-width: 0 3px 3px 0;
              // display: inline-block;
              padding: 3px;
              position: absolute;
            }
            .right-icon-3 {
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              left: 18px;
              top: -3px;
            }
          }
          &:hover .line-width-icon-3 {
            width: 14%;
            left: 0rem;
            &::before {
              width: 76%;
              margin-left: 15px;
            }
            i {
              margin-left: 25px;
            }
          }
        }
      }
      //
      .slick-dots {
        text-align: left;
        left: 0;
        bottom: -9rem;
        li {
          margin: 0 10rem 0 11px;
        }
        button {
          display: block;
          width: 11rem;
          height: 0.2rem;
          padding: 0;

          border: none;

          background-color: rgb(214, 214, 214);

          text-indent: -9999px;
        }
        li.slick-active button {
          background-color: $black;
        }
      }
    }
  }
}
@media (max-width: 1025px) {
  .project-trusted {
    padding: 4em 0;
  }
  .project-trusted .container .padding-project-trusted {
    width: auto;
  }
}
@media (max-width: 769px) {
  .project-trusted .container h2 {
    font-size: 42px;
  }
  .project-trusted .container .padding-project-trusted .col-xxl-3 .project-trusted-button {
    height: 74px;
    width: 214px;
    font-size: 20px;
  }
  .project-trusted
    .container
    .padding-project-trusted
    .col-xxl-3:hover
    .trusted-hover-buttom
    .line-width-icon-3 {
  }

  .project-trusted
    .container
    .padding-project-trusted
    .col-xxl-3:hover
    .trusted-hover-buttom
    .line-width-icon-3
    .right-icon-3 {
    left: 15px;
  }
  .project-trusted .container .padding-project-trusted .slick-dots button {
    width: 5rem;
  }
  .project-trusted .container .padding-project-trusted .slick-dots li {
    margin: 0 5rem 0 11px;
  }
  .project-trusted .container .padding-project-trusted {
    padding: 2em 0 4em 0;
  }
  .project-trusted .container .padding-project-trusted .slick-dots {
    bottom: -5rem;
  }
}
@media (max-width: 541px) {
  .project-trusted .container .col-xxl-7 p {
    font-size: 17px;
  }
  .project-trusted .container .padding-project-trusted {
    padding: 0em 0 4em 0;
  }
  .project-trusted .container .padding-project-trusted .slick-dots li {
    margin: 0 3rem 0 0px;
    left: 12px;
  }
  .project-trusted .container .padding-project-trusted .slick-dots button {
    width: 3rem;
  }
}

//

@import './src/assets/_color.scss';

.insights {
  background-color: $blue;
  padding: 5em 10em;

  .title {
    color: $white;
    font-size: $fz-65;
    font-weight: 600;
    padding: 0 0 23px 0;
  }

  .content {
    flex-wrap: unset;

    .left {
      height: 600px;
      margin-right: 4px;

      img {
        width: 100%;
        height: 600px;
        object-fit: cover;
      }
    }

    .right {
      .items {
        margin-bottom: 28px;

        img {
          width: 100%;
          height: 286px;
          object-fit: cover;
         
        }
      }
    }
  }
}

@media (max-width:768px) {
  .insights {
    padding: 1em 2em;
  
    .title {
      color: $white;
      font-size: $fz-20;
      font-weight: 600;
    }
  
    .content {
      flex-wrap: wrap;
  
      .left {
        position: relative;
        height: 200px;
        margin-bottom: 10px;

        .content {
          position: absolute;
          left: 15px;
          top: 120px;
        }

        img {
          height: 200px;
        }
      }
  
      .right {  
        .items {
          margin-bottom: 0;

          .item {
            margin-bottom: 10px;
          }

          img {
            height: 200px;
          }
        }
      }
    }
  }
}

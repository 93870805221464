$white: #ffffff;
$blue: #0055a5;
$light-blue: #1c6ab3;
$black: #000000;

$red: #f00000;
$gray-800: #CDCDDE;
$white-hex:#ffffff00;
$fz-10:10px;
$fz-12:12px;
$fz-14:14px;
$fz-16:16px;
$fz-18:18px;
$fz-20:20px;
$fz-25:25px;
$fz-35:35px;
$fz-65:65px;
$background-image: linear-gradient(150deg, #005db4, #005db4, #b4d4f1);
$border-album: #bfbfbf;
$border-album-active: #6a6a6a;
$shadow-album: rgba(0, 0, 0, 0.3);
$slick-dot-bg: #e5e7e9;
$office-color: #a5a5a5;
$info-color: #383838;
$text-color-default: #404040;
$text-color-hover: #f8f8f8;
$box-shadow-card1: rgba(0, 0, 0, 0.08);
$box-shadow-card2: rgba(0, 0, 0, 0.05);
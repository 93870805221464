@import './src/assets/_color.scss';

.item {
    position: relative;
    width: 100%;
    z-index: 10;
   
    .background {
        position: absolute;
        width: 100%;
        z-index: 0;

        &:hover {
            background-color:#009BDB;

            img {
                opacity: 0;
            }
        }
    }

    .content {
        position: absolute;
        z-index: 1;
        left: 20px;
        bottom: 0;
        color: $white;

        &:hover + #background {
            background-color:#009BDB;
    
            img {
                opacity: 0;
            }
        }

        .time {
            font-size: $fz-16;
        }
        
        .title {
            font-size: $fz-25;
        }
    }  
}

@media (max-width:768px) {
    .item {
        .content {
            left: 15px;
            bottom:- 10px;

            .time {
                font-size: $fz-10;
            }
            
            .title {
                font-size: $fz-14;
            }
        }  
    }
}
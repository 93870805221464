@import './src/assets/_color.scss';

.arrow-wrapper {
  $size: 15px;
  $width: 40px;

  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  & .circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    bottom: 50%;
    right: -40%;
    transform: translateY(50%);
    border: 1px solid;
  }

  & .white {
    color: $white;
    // font-weight: bold;
  }

  & .black {
    color: $black;
    // font-weight: bold;
  }

  & .explore {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    &.explore-left {
      padding-left: $size;
    }

    &.explore-right {
      padding-right: $size;
    }
  }

  &:hover .arrow {
    @each $direction in 'left' 'right' {
      &.arrow--#{$direction} {
        & span {
          width: $width;
        }

        & span,
        &:before,
        &:after {
          #{$direction}: $width * -1;
        }
      }
    }
  }

  & .arrow {
    cursor: pointer;
    display: block;
    position: relative;
    & span,
    &:before,
    &:after {
      content: '';
      display: block;
      width: $size / 2;
      height: 1px;
      position: absolute;
      top: calc(50% - 1px); // height
    }

    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }

    & span {
      width: $width - $size;
    }

    &.arrow--black {
      & span,
      &:before,
      &:after {
        background: $black;
      }
    }

    &.arrow--white {
      & span,
      &:before,
      &:after {
        background: $white;
      }
    }

    @each $direction in 'left' 'right' {
      &.arrow--#{$direction} {
        & span,
        &:before,
        &:after {
          #{$direction}: ($width - $size) * -1;
          transform-origin: #{$direction} 50%;
        }

        &:before,
        &:after {
          transition: #{$direction} 0.3s 0.05s;
        }

        & span {
          transition: all 0.3s 0.05s;
        }
      }
    }
  }
}

@import './src/assets/_color.scss';
//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .header {
    // display: flex;
    // justify-content: space-between;
    // align-items: stretch;
    // position: relative;
    // z-index: 2;
    // height: get($header-config, desktop, default, height);
    // transition: left get($aside-config, transition-speed) ease;
    // border-bottom: get($header-config, desktop, default, border);

    // Header Logo
    // .header-logo {
    // 	padding: 0;
    // 	display: flex;
    // 	align-items: center;

    // 	// Logos
    // 	.logo-default {
    // 		display: inline-block;
    // 	}

    // 	.logo-sticky {
    // 		display: none;
    // 	}
    // }

    // Header menu
    // .header-menu {
    // 	display: flex;
    // }

    // Fixed header & sticky header modes
    .header-fixed[data-kt-sticky-header='on'] & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, desktop, fixed, z-index);
      background-color: get($header-config, desktop, fixed, bg-color);
      box-shadow: get($header-config, desktop, fixed, box-shadow);
      height: get($header-config, desktop, fixed, height);
      border-bottom: get($header-config, desktop, fixed, border);
      padding: 0;

      // Header Logo
      .header-logo {
        // Logos
        .logo-default {
          display: none;
        }

        .logo-sticky {
          display: inline-block;
        }
      }

      // Select languages
      .topbar {
        .select {
          color: #5e6278;

          .selected {
            color: #5e6278;
          }
        }
      }
    }

    // Fixed header & NON sticky header modes
    body:not([data-kt-sticky-header='on']) & {
      // Header menu
      .header-menu {
        .menu {
          // Root links
          & > .menu-item {
            // Customize menu link default state
            @include menu-link-default-state(
              $title-color: #fff,
              $icon-color: #4a4b68,
              $bullet-color: #9899ac,
              $arrow-color: #494b69,
              $bg-color: null,
              $all-links: false
            );

            // Customize menu link hover state
            @include menu-link-hover-state(
              $title-color: $white,
              $icon-color: $white,
              $bullet-color: $white,
              $arrow-color: $white,
              $bg-color: rgba($white, 0.1),
              $all-links: false
            );

            // Customize menu link show state
            @include menu-link-show-state(
              $title-color: $white,
              $icon-color: $white,
              $bullet-color: $white,
              $arrow-color: $white,
              $bg-color: rgba($white, 0.1),
              $all-links: false
            );

            // Customize menu link active state
            @include menu-link-active-state(
              $title-color: $white,
              $icon-color: $danger,
              $bullet-color: $danger,
              $arrow-color: $white,
              $bg-color: rgba($white, 0.1),
              $all-links: false
            );
          }
        }
      }
    }
  }

  // Header on homepage
  .header[data-kt-sticky-homepage='homepage'] {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.08);
    padding: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    z-index: 97;
    height: get($header-config, desktop, default, height);
    transition: left get($aside-config, transition-speed) ease;

    // Header Logo
    .header-logo {
      padding: 0;
      display: flex;
      align-items: center;

      // Logos
      .logo-default {
        display: inline-block;
      }

      .logo-sticky {
        display: none;
      }
    }

    // Header menu
    .header-menu {
      display: flex;

      .menu {
        .menu-item {
          .menu-title {
            color: $white;
          }
        }
      }
    }

    // Select languages
    .topbar {
      .select {
        color: $white;

        .selected {
          color: $white;
        }
      }

      .nav-input {
        background-color: transparent;

        &:hover {
          opacity: 1;
          transition: color 0.2s ease, background-color 0.2s ease;
          background-color: rgba(255, 255, 255, 0.1);
		  border-radius: 0.475rem;
		  
		  & span {
			color: $white;
			position: relative;
			line-height: 1.5;
			padding: .2rem;
			  
			&::before {
			  content: "";
			  position: absolute;
			  height: 2px;
			  width: 100%;
			  background-color: #000;
			  bottom: 0;
			  animation-name: example;
			  animation-duration: 0.2s;
			}
		  }

        }
      }
    }

    .header-fixed[data-kt-sticky-header='on'] & {
      // Header menu
      .header-menu {
        display: flex;

        .menu {
          .menu-item {
            .menu-title {
              color: #5e6278 !important;
            }
          }
        }
      }

      .nav-input {
        background-color: $text-color-default;
		
		& span {
			color: $white;
			position: unset;

			&::before {
			  content: "";
			  display: block;
			  clear: both;
			}
		}

		&:hover {
		  	opacity: 0.5;
		   border-radius: unset;
		}
      }
    }
  }

  // Header on other pages
  .header[data-kt-sticky-homepage='other'] {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 2;
    height: get($header-config, desktop, default, height);
    transition: left get($aside-config, transition-speed) ease;
    border-bottom: get($header-config, desktop, default, border);

    // Header Logo
    .header-logo {
      padding: 0;
      display: flex;
      align-items: center;

      // Logos
      .logo-default {
        display: none;
      }

      .logo-sticky {
        display: inline-block;
      }
    }

    // Header menu
    .header-menu {
      display: flex;

      .menu {
        .menu-item {
          .menu-title {
            color: #5e6278 !important;
          }
        }
      }
    }

    // Select languages
    .topbar {
      .select {
        color: #5e6278;

        .selected {
          color: #5e6278;
        }
      }

		.nav-input {
			&:hover {
				& span {
					color: $white;
				}
			}
		}	
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: get($header-config, tablet-and-mobile, default, height);
    position: relative;
    z-index: 3;
    border-bottom: get($header-config, tablet-and-mobile, default, border);

    // Header Logo
    .header-logo {
      // Logos
      .logo-default {
        display: none;
      }

      .logo-sticky {
        display: inline-block;
      }
    }

    // Header menu
    .header-menu {
      display: none;
      // left: -33%;
      // top: 67px;
      z-index: get($header-config, tablet-and-mobile, fixed, z-index) - 1;
    }

    // Select languages
    .topbar {
      .select {
        color: #5e6278;

        .selected {
          color: #5e6278;
        }
      }
    }

    body:not([data-kt-sticky-header='on']) & {
      .topbar .btn.btn-icon .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: #5e6278 !important;
      }
    }

    // Fixed header and header sticky modes
    .header-tablet-and-mobile-fixed[data-kt-sticky-header='on'] & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, tablet-and-mobile, fixed, z-index);
      height: get($header-config, tablet-and-mobile, fixed, height);
      min-height: get($header-config, tablet-and-mobile, fixed, height);
      background-color: get($header-config, tablet-and-mobile, fixed, bg-color);
      box-shadow: get($header-config, tablet-and-mobile, fixed, box-shadow);
      border-bottom: get($header-config, tablet-and-mobile, fixed, border);

      // Header Logo
      .header-logo {
        // Logos
        .logo-default {
          display: none;
        }

        .logo-sticky {
          display: inline-block;
        }
      }

      .topbar .btn.btn-icon .svg-icon svg [fill]:not(.permanent):not(g) {
        fill: #5e6278 !important;
      }
    }
  }
}

@import './src/assets/_color.scss';
.services-home-container {
  margin: 6rem auto;
  .title {
    font-size: 67px;
  }
  .sub-title {
    font-size: 20px;
  }
  @media (max-width: 767.98px) {
    margin: 0 auto;
    .title {
      font-size: 38px;
      padding-top: 1rem;
    }
    .sub-title {
      font-size: 16px;
      padding-bottom: 2rem;
    }
  }
}
.services-container {
  background-image: url('/media/images/img-services2.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: calc(100vh - 80px);
  position: relative;
  .overlay-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.7);
  }
  .services-content {
    color: $white;
    .title {
      font-size: 67px;
      padding: 6rem 0 2rem;
      position: relative;
    }
    .content {
      padding: 15px 0;
      .card {
        position: relative;
        border-radius: 4px;
        border: solid 1px rgba($color: $white, $alpha: 0.5);
        box-shadow: 0 6px 10px $box-shadow-card1, 0 0 6px $box-shadow-card2;
        transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
          0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
        padding: 20px;
        background-color: transparent;
        height: 24rem;
        .card-title {
          font-size: 37px;
          color: $white;
        }
        .card-text {
          margin-top: 10px;
          font-size: 16px;
        }
        .arrow-wrapper {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }

        .footer-card {
          &_button {
            position: relative;
            background: transparent;
            margin-top: 6rem;

            &_explore {
              color: $white;
              font-weight: bold;
            }

            &:before {
              content: '';
              width: 25px;
              height: 2px;
              background: $text-color-default;
              position: absolute;
              top: 50%;
              margin-top: -1px;
              left: 150px;
              transition: all 0.3s ease;
            }

            &:hover:before {
              width: 35px;
              background: $white;
            }

            &:after {
              content: '';
              width: 0;
              border-style: solid;
              border-width: 5px 0 5px 5px;
              border-color: transparent transparent transparent $text-color-default;
              position: absolute;
              top: 50%;
              margin-top: -5px;
              left: 175px;
              transition: all 0.3s ease;
            }

            &:hover:after {
              left: 185px;
            }
          }
        }

        &:hover {
          transform: scale(1.05);
          background-image: $background-image;
          .card-title,
          .card-text,
          .footer-card_button {
            color: $text-color-hover;
          }
          .footer-card {
            &_button {
              &:before {
                background: $white;
              }
              &_explore {
                color: $white;
              }
              &:after {
                border-color: transparent transparent transparent $white;
              }
            }
          }
        }
      }
    }
    .home-padding {
      padding: 5rem 0;
    }
  }
  @media (max-width: 767.98px) {
    height: auto;
    .services-content {
      margin: 0;
      .title {
        font-size: 25px;
        font-weight: 600;
        padding: 2.5rem 0 0;
      }
      .content {
        margin-top: 0;
        .card {
          margin-top: 20px;
          .card-title {
            font-size: 20px;
          }
        }
      }
      .home-padding {
        padding: 15px 0;
      }
    }
  }
}

.services-detail-container {
  .banner-service-container {
    .overlay-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: $black, $alpha: 0.7);
    }
    .banner-img {
      display: block;
      width: 100%;
      height: calc(100vh - 80px);
    }
    .inner-banner {
      position: absolute;
      top: 25%;
      left: 15%;
      color: $white;
      max-width: 40rem;
      .text-title {
        font-size: 3.5vw;
      }
      .text-content {
        margin-top: 10px;
        font-size: 20px;
        text-align: justify;
        text-justify: inter-word;
      }
      .arrow-back {
        margin-left: 30px;
      }
    }
  }

  .services-detail-content {
    // margin: 5rem auto;
    .img-icon {
      margin: 10px 0;
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .text-title {
      font-size: 37px;
      color: $black;
      font-weight: 600;
      max-width: 70%;
      margin-top: 5rem;
    }
    .text-content {
      font-size: 20px;
      margin-top: 10px;
      max-width: 65rem;
      text-align: justify;
      text-justify: inter-word;
    }
    .row-content {
      margin: 2rem 0;
      text-align: left;
      .col-item {
        margin: 20px 0;
      }
    }
  }

  .related-service-container {
    background-color: $blue;
    .related-service-content {
      justify-content: center;
      align-items: center;
      padding: 5rem 0;
      .text-title {
        font-size: 37px;
        color: $white;
      }
      .text-content {
        font-size: 25px;
        color: $white;
        margin-top: 20px;
        span:first-child {
          font-size: 18px;
          margin-right: 30px;
          font-weight: 300;
        }
      }
      .img-relate {
        width: 100%;
        height: 450px;
        object-fit: contain;
        object-position: left;
      }
    }
  }

  @media (max-width: 767.98px) {
    .banner-service-container {
      .banner-img {
        height: auto;
      }
      .inner-banner {
        top: 50%;
        left: 10px;
        padding: 0 15px;
        max-width: 100%;
        transform: translateY(-50%);
        .text-title {
          font-size: 16px;
        }
        .text-content {
          font-size: 16px;
          text-align: justify;
          text-justify: inter-word;
        }
      }
    }
    .services-detail-content {
      .text-title {
        font-size: 20px !important;
        max-width: 100%;
        margin-top: 2rem;
      }
      .text-content {
        font-size: 16px;
        max-width: 100%;
        text-align: justify;
        text-justify: inter-word;
      }
      .row-content {
        text-align: center;
        .col-item {
          margin: 0;
        }
      }
    }
    .related-service-container {
      .related-service-content {
        padding: 30px 0;
        .text-title {
          font-size: 20px;
        }
        .text-content {
          font-size: 16px;
          text-align: justify;
          text-justify: inter-word;
          span:first-child {
            font-size: 14px;
            margin-right: 30px;
            font-weight: 300;
          }
        }
        .img-relate {
          margin-top: 30px;
          height: 250px;
          object-position: center;
        }
      }
    }
  }
}
